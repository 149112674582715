 //modal
 $(document).ready(function() {

     $("#js-close-modal").click(function (){
         $("#result_modal").modal("hide");
     });

    $('#btn-modal-fibre-dediee').click(function (e)
    {
        e.preventDefault()
        $("#drop_down_text").text("FTTO – Active Ethernet")
        hide_double_search_bar();
        choice = 0;
        if(marker_1 != null){
            marker_1.setMap(null)
            marker_1 = null;
        }
        if(marker_2 != null){
            marker_2.setMap(null)
            marker_2 = null;
        }

        let input1 = document.getElementById('inputAddress1')
        input1.value = ""
        $(input1).removeClass("is-invalid")


        let input2 = document.getElementById('inputAddress2')
        input2.value = ""
        $(input2).removeClass("is-invalid")
    })


    $('#btn-modal-FON').click(function (e)
    {
        e.preventDefault()
        $("#drop_down_text").text("Offre Passive - FON - Fibre noire")

        show_double_search_bar();
        choice = 1;
        if(marker_1 != null){
            marker_1.setMap(null)
            marker_1 = null;
        }
        let input = document.getElementById('inputAddress')
        input.value = ""
        $(input).removeClass("is-invalid")
    })

    $('#search-btn').click(function (e)
    {
        e.preventDefault();
        if (choice === 0){
            let input = document.getElementById('inputAddress');
            if(input.value !== ""){
                $(input).removeClass("is-invalid")
                searchOffer1(document.getElementById('inputAddress').value);
            }else{
                $(input).addClass("is-invalid")
            }
        }else{

            let input1 = document.getElementById('inputAddress1');
            let input2 = document.getElementById('inputAddress2');

            if(input1.value !== "" && input2.value !== ""){

                $(input1).removeClass("is-invalid")
                $(input2).removeClass("is-invalid")

                searchOffer2(input1.value, input2.value);
            }else{
                if(input1.value === "")
                $(input1).addClass("is-invalid")

                if(input2.value === "")
                $(input2).addClass("is-invalid")
            }
        }
    })
});

$(document).on("keyup", "#inputAddress", function(e) {
    if (e.which === 13) {
        searchOffer1(document.getElementById("inputAddress").value)
    }
})

$(document).on("keyup", "#inputAddress1", function(e) {
    if (e.which === 13) {
        $("#inputAddress2").focus()
    }
})

$(document).on("keyup", "#inputAddress2", function(e) {
    if (e.which === 13) {
        searchOffer2(document.getElementById("inputAddress1").value, document.getElementById("inputAddress2").value)
    }
})